.snip1515 {
    font-family: 'Open Sans', Arial, sans-serif;
    position: relative;
    margin: 10px;
    min-width: 230px;
    max-width: 315px;
    width: 100%;
    color: #000000;
    text-align: center;
    line-height: 1.4em;
    font-size: 14px;
    box-shadow: none !important;
  }
  
  .snip1515 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .snip1515 .profile-image {
    display: inline-block;
    width: 80%;
    z-index: 1;
    position: relative;
    padding: 10px;
    border: 2px solid red;
  }
  
  .snip1515 .profile-image img {
    max-width: 100%;
    vertical-align: top;
  }
  
  .snip1515 figcaption {
    width: 100%;
    background-color: #F2F2F2;
    color: #555;
    padding: 125px 25px 25px;
    margin-top: -100px;
    display: inline-block;
  }
  
  .snip1515 h3,
  .snip1515 h4,
  .snip1515 p {
    margin: 0 0 5px;
  }
  
  .snip1515 h3 {
    font-weight: 600;
    font-size: 1.3em;
    font-family: 'Playfair Display', Arial, sans-serif;
  }
  
  .snip1515 h4 {
    color: #8c8c8c;
    font-weight: 400;
    letter-spacing: 2px;
  }
  
  .snip1515 p {
    font-size: 0.9em;
    letter-spacing: 1px;
    opacity: 0.9;
  }
  
  .snip1515 .icons {
    text-align: center;
    width: 100%;
  }
  
  .snip1515 i {
    padding: 10px 2px;
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    color: #e00707;
    opacity: 0.75;
  }
  
  .snip1515 i:hover {
    opacity: 1;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }