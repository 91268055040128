/*VERTICAL MENU*/
nav.vertical{
    position:relative;
    background:rgb(248, 248, 248);
  }
  
  /* ALL UL */
  nav.vertical ul{

    list-style: none;
    padding: 0%;
  }
  /* ALL LI */
  nav.vertical li{
    position:relative;
  }
  /* ALL A */
  nav.vertical a{
    display:block;
    color:rgb(12, 12, 12);
    text-decoration:none;
    padding:10px 15px;
    transition:0.2s;
    font-weight: 900;
  }
  /* ALL A HOVER */
  nav.vertical li:hover > a{
    background:rgb(255, 82, 82);
  }
  
  /* INNER UL HIDE */
  nav.vertical ul ul{
    background:rgba(0,0,0,0.1);
    padding-left:20px;
    transition: max-height 0.2s ease-out;
    max-height:0;
    overflow:hidden;
  }
  /* INNER UL SHOW */
  nav.vertical li:hover > ul{
    max-height:500px;
    padding: 0%;
    transition: max-height 0.25s ease-in;
  }