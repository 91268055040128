
$main-red: #bd150b !default;
$main-red-rgb-015: rgba(189, 21, 11, 0.1) !default;

/* Cards */
.postcard {
    flex-wrap: wrap;
    display: flex;
    
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    border-radius: 10px;
    margin: 0 0 2rem 0;
    overflow: hidden;
    position: relative;
    color: #ffffff;
  
      &.dark {
          background-color: #18151f;
      }
      &.light {
          background-color: #fdfeff;
      }
      
      .t-dark {
          color: #18151f;
      }
  
      
      .postcard__title,	.h1 {
          margin-bottom: 0.5rem;
          font-weight: 500;
          line-height: 1.2;
      }
      
      .small {
          font-size: 80%;
      }
  
    .postcard__title {
      font-size: 1.75rem;
    }
  
    .postcard__img {
      max-height: 180px;
      width: 100%;
      object-fit: cover;
      position: relative;
    }
  
    .postcard__img_link {
      display: contents;
    }
  
    .postcard__bar {
      width: 50px;
      height: 10px;
      margin: 10px 0;
      border-radius: 5px;
      background-color: #ffffff;
      transition: width 0.2s ease;
    }
  
    .postcard__text {
      padding: 1.5rem;
      position: relative;
      display: flex;
      flex-direction: column;
    }
  
    .postcard__preview-txt {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify;
      height: 100%;
    }
  
    .postcard__tagbox {
      display: flex;
      flex-flow: row wrap;
      font-size: 14px;
      margin: 20px 0 0 0;
          padding: 0;
      justify-content: center;
  
      .tag__item {
        display: inline-block;
        background: rgba(83, 83, 83, 0.4);
        border-radius: 3px;
        padding: 2.5px 10px;
        margin: 0 5px 5px 0;
        cursor: default;
        user-select: none;
        transition: background-color 0.3s;
  
        &:hover {
          background: rgba(83, 83, 83, 0.8);
        }
      }
    }
  
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(-70deg, #424242, transparent 50%);
      opacity: 1;
      border-radius: 10px;
    }
  
    &:hover .postcard__bar {
      width: 100px;
    }
  }
  
  @media screen and (min-width: 769px) {
    .postcard {
      flex-wrap: inherit;
  
      .postcard__title {
        font-size: 2rem;
      }
  
      .postcard__tagbox {
        justify-content: start;
      }
  
      .postcard__img {
        max-width: 300px;
        max-height: 100%;
        transition: transform 0.3s ease;
      }
  
      .postcard__text {
        padding: 3rem;
        width: 100%;
      }
  
      .media.postcard__text:before {
        content: "";
        position: absolute;
        display: block;
        background: #18151f;
        top: -20%;
        height: 130%;
        width: 55px;
      }
  
      &:hover .postcard__img {
        transform: scale(1.1);
      }
  
      &:nth-child(2n+1) {
        flex-direction: row;
      }
  
      &:nth-child(2n+0) {
        flex-direction: row-reverse;
      }
  
      &:nth-child(2n+1) .postcard__text::before {
        left: -12px !important;
        transform: rotate(4deg);
      }
  
      &:nth-child(2n+0) .postcard__text::before {
        right: -12px !important;
        transform: rotate(-4deg);
      }
    }
  }
  @media screen and (min-width: 1024px){
          .postcard__text {
        padding: 2rem 3.5rem;
      }
          
          .postcard__text:before {
        content: "";
        position: absolute;
        display: block;
        
        top: -20%;
        height: 130%;
        width: 55px;
      }
      
    .postcard.dark {
          .postcard__text:before {
              background: #18151f;
          }
    }
      .postcard.light {
          .postcard__text:before {
              background: #ffffff;
          }
    }
  }
  
  /* COLORS */

  
  .postcard .postcard__tagbox .red.play:hover {
      background: $main-red;
  }
  .red .postcard__title:hover {
      color: $main-red;
  }
  .red .postcard__bar {
      background-color: $main-red;
  }
  .red::before {
      background-image: linear-gradient(-30deg, $main-red-rgb-015, transparent 50%);
  }
  .red:nth-child(2n)::before {
      background-image: linear-gradient(30deg, $main-red-rgb-015, transparent 50%);
  }
  

  
  @media screen and (min-width: 769px) {
     
      .red::before {
          background-image: linear-gradient(-80deg, $main-red-rgb-015, transparent 50%);
      }
      .red:nth-child(2n)::before {
          background-image: linear-gradient(80deg, $main-red-rgb-015, transparent 50%);
      }
      
      
  }
  