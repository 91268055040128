.mapGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    padding: 50px;
}



.chk {
    margin-bottom: 5px
}

.rck {
    margin-top: 20px;
    padding-bottom: 15px
}

@media only screen  and (max-width:688px){
    .mapGrid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }
    
}
@media only screen  and (min-width:689px) and (max-width:990px){
    .mapGrid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
    
}