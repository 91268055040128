.h {
	
	text-align: center;
	width: 100%;
	height: auto;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	overflow: hidden;
	border-radius: 0 0 85% 85% / 30%;
	margin-top: 80px;
}
.h .overlay{
	width: 100%;
	height: 100%;
	padding: 50px;
	color: #FFF;
	text-shadow: 1px 1px 1px #333;
  background-image: linear-gradient( 135deg, #006f45 10%, #ed1a37 100%);
	
}

.h .overlay h1 {

	font-size: 50px;
	margin-bottom: 30px;
}

.h .overlay h3, p {
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 30px;
}

.h .overlay button {
	border: none;
	outline: none;
	padding: 10px 20px;
	border-radius: 50px;
	color: #333;
	background: #fff;
	margin-bottom: 50px;
	box-shadow: 0 3px 20px 0 #0000003b;
}
.h .overlay button:hover{
	cursor: pointer;
}
.galleryWrap {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	align-items: center;
	justify-content: center;
	max-width: 1200px;
	margin: 0 auto;
  }
  
  .galleryWrap .single {
	border: 5px solid transparent;
box-sizing: border-box;
width: 30.33%;
float: left;
position: relative;
cursor: pointer;
  }
  
  .galleryWrap .single img {
	max-width: 100%;
vertical-align: middle;
  }
  
  .galleryWrap .single img:hover {
	transform: scale(1.02);
  }
  
  .sliderWrap {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
	position: fixed;
	cursor: pointer;
	opacity: 0.6;
	color: #fff;
	z-index: 9999;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
	opacity: 1;
  }
  
  .sliderWrap .btnClose {
	top: 80px;
	right: 40px;
  }
  
  .sliderWrap .btnPrev {
	top: 50%;
	transform: translateY(-50%);
	left: 40px;
  }
  
  .sliderWrap .btnNext {
	top: 50%;
	transform: translateY(-50%);
	right: 40px;
  }
  
  .fullScreenImage {
	width: calc(100% - 40px);
	height: calc(100% - 40px);
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .fullScreenImage img {
	max-width: 100%;
	max-height: 100%;
	pointer-events: none;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */  
  }
  ::selection{
	color: #fff;
	background: #ed1a37;
  }

  .wrapper{
	margin: 100px auto;
	max-width: 1100px;
  }
  .wrapper nav{
	display: flex;
	justify-content: center;
  }
  .wrapper .items{
	display: flex;
	max-width: 300px;
	width: 100%;
	justify-content: space-between;
	
  }
  .items span{
	padding: 7px 25px;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
	color: #ed1a37;
	border-radius: 50px;
	border: 2px solid #ed1a37;
	transition: all 0.3s ease;
  }
  .items span.active,
  .items span:hover{
	color: #fff;
	background: #ed1a37;
  }
  .gallery{
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
  }
  .gallery .image{
	width: calc(100% / 4);
	padding: 7px;
  }
  .gallery .image span{
	display: flex;
	align-items: center;
    justify-content: center;
	width: 100%;
	overflow: hidden;
  }
  .gallery .image img{
	width: 100%;
	vertical-align: middle;
	transition: all 0.3s ease;
	border-radius: 5px;
  }
  .gallery .image:hover img{
	transform: scale(1.1);
  }
  .gallery .image.hide{
	display: none;
  }
  .gallery .image.show{
	animation: animate 0.4s ease;
  }
  @keyframes animate {
	0%{
	  transform: scale(0.5);
	}
	100%{
	  transform: scale(1);
	}
  }
  .preview-box{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.9);
	background: #fff;
	max-width: 700px;
	width: 100%;
	z-index: 5;
	opacity: 0;
	pointer-events: none;
	border-radius: 3px;
	padding: 0 5px 5px 5px;
	box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
  }
  .preview-box.show{
	opacity: 1;
	pointer-events: auto;
	transform: translate(-50%, -50%) scale(1);
	transition: all 0.3s ease;
  }
  .preview-box .details{
	padding: 13px 15px 13px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
  }
  .details .title{
	display: flex;
	font-size: 18px;
	font-weight: 400;
  }
  .details .title p{
	font-weight: 500;
	margin-left: 5px;
  }
  .details .icon{
	color: #007bff;
	font-style: 22px;
	cursor: pointer;
  }
  .preview-box .image-box{
	width: 100%;
	display: flex;
  }
  .image-box img{
	width: 100%;
	border-radius: 0 0 3px 3px;
  }
  .shadow{
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
	display: none;
	background: rgba(0,0,0,0.4);
  }
  .shadow.show{
	display: block;
  }
  @media (max-width: 1000px) {
	.gallery .image{
	  width: calc(100% / 3);
	}
  }
  @media (max-width: 800px) {
	.gallery .image{
	  width: calc(100% / 2);
	}
  }
  @media (max-width: 700px) {
	.wrapper nav .items{
	  max-width: 600px;
	}
	nav .items span{
	  padding: 7px 15px;
	}
  }
  @media (max-width: 600px) {
	.wrapper{
	  margin: 30px auto;
	}
	.wrapper nav .items{
	  flex-wrap: wrap;
	  justify-content: center;
	}
	nav .items span{
	  margin: 5px;
	}
	.gallery .image{
	  width: 100%;
	}
  }
  @media only screen  and (max-width:688px){
   
	.h {
	
		text-align: center;
		width: 100%;
		height: auto;
		background-size: cover;
		background-attachment: fixed;
		position: relative;
		overflow: hidden;
		border-radius: 0 0 85% 85% / 30%;
		margin-top: 0px;
	}
}
@media only screen  and (min-width:689px) and (max-width:990px){
	.h {
	
		text-align: center;
		width: 100%;
		height: auto;
		background-size: cover;
		background-attachment: fixed;
		position: relative;
		overflow: hidden;
		border-radius: 0 0 85% 85% / 30%;
		margin-top: 0px;
	}
  
}