
.f{
    display: unset;
    border-bottom: 5px solid rgb(10, 1, 1);
}
.navImg{
    display: none;
}
.text1{
    color: black!important;
    font-weight: bolder!important;
}
.text2{
    color: white!important;
    font-weight: bolder!important;
}

.navbar-links {
    list-style-type: none;
    display: flex;
  }
  .navbar-links li a {
    display: block;
    text-decoration: none;
    color: #444;
    padding: 20px 20px;
    font-weight: 700;
    transition: 0.4s all;
  }
  .navbar-links li a:hover {
    display: block;
    text-decoration: none;
    background-color: #c0bbbbc9;
    padding: 20px 20px;
    font-weight: 700;
    transition: 0.4s all;
  }
  .navbar-links li.navbar-dropdown {
    position: relative;
    text-align: left;
  }
  
  .navbar-links li.navbar-dropdown:hover .dropdown {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
  
  .navbar-links li.navbar-dropdown .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: 20px 0;
    top: 100%;
    transform: translateY(50px);
    left: 0;
    width: 200px;
    background-color: rgb(36, 30, 30);
    box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 111;
    transition: 0.4s all;
    color: white;
  }
  .navbar-links li.navbar-dropdown .dropdown a {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    color: rgb(247, 15, 15);
  }
  .navbar-links li.navbar-dropdown .dropdown a:hover {
    background-color: rgb(122, 119, 113);
  }
  .navbar-dropdown .dropdown a:hover {
    padding-left: 30px;
  }
  .navbar-links li a:hover {
    color: #ff3f34;
  }
@media only screen  and (max-width:688px){
    .f{
        display: none;
        
    }
    .navImg{
        display: unset;
    }
    #navM{
      background-color:#c60000 ;
    }
}
@media only screen  and (min-width:689px) and (max-width:990px){
    .f{
        display: none;
        
    }
    .navImg{
        display: unset;
    }
  
}