.shop-container{
    display: grid;
    grid-template-columns: 3fr 1fr;
}
.products-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 45px;
    margin: 100px 50px;
}
.cart-container{
    background-color: rgba(255, 153, 0, 0.3);
}

.pagination button{
    margin-right: 10px;
    background-color: white;
    border: 1px solid orange;
}
.pagination .selected{
    background-color: orange;
    color: white;
}
.search-container{
    background-color: rgba(0, 0, 0, 0.85);
    text-align: center;
    line-height: 40px;
}
.search-container input{
    width: 75%;
}

@media screen and (max-width:1100px) {
    .products-container{
        grid-template-columns: repeat(1, 1fr);
    }
}