.gridEv{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}


@media only screen  and (max-width:688px){
    .gridEv{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

}
@media only screen  and (min-width:689px) and (max-width:990px){
    .gridEv{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }
  
}