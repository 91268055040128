

.recentN{
    display: flex;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; ;
}
.imgContainer{
    width: 30%;
    padding: 10px;
}

.descriptionContainer{
    width: 70%;
    text-align: left;
    padding: 10px;
}
.oldNoticeContainer{
    display: grid;
    grid-template-columns: repeat(3 ,1fr);
    grid-gap: 10px;
    padding: 10px;
}
.asideC{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; 
    padding: 1px 10px;
}
@media only screen  and (max-width:688px){
    .recentN{
        display: flex;
        flex-direction: column;
       box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; ;
    }
    .imgContainer{
        width: 100%;
        padding: 10px;
    }
    
    .descriptionContainer{
        width: 100%;
        text-align: left;
        padding: 10px;
    }
    .oldNoticeContainer{
        display: grid;
        grid-template-columns: repeat(1 ,1fr);
        grid-gap: 10px;
    }
}


@media only screen  and (min-width:689px) and (max-width:990px){

    .recentN{
        display: flex;
        flex-direction: column;
       box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; ;
    }
    .imgContainer{
        width: 100%;
        padding: 10px;
    }
    .oldNoticeContainer{
        display: grid;
        grid-template-columns: repeat(1 ,1fr);
        grid-gap: 10px;
    }
    
    .descriptionContainer{
        width: 100%;
        text-align: left;
        padding: 10px;
    }
}